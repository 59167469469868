import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { KieranPubKey } from "Const";
import { FormattedMessage } from "react-intl";
import { TLVEntryType, encodeTLVEntries } from "@snort/system";
import { bech32ToHex } from "SnortUtils";
export default function HelpPage() {
    return (_jsxs(_Fragment, { children: [_jsx("h2", { children: _jsx(FormattedMessage, { id: '7hp70g', defaultMessage: 'NIP-05' }) }), _jsx("p", { children: _jsx(FormattedMessage, { id: 'c35bj2', defaultMessage: 'If you have an enquiry about your NIP-05 order please DM {link}', values: {
                        link: (_jsx(Link, { to: `/messages/${encodeTLVEntries("chat4", {
                                type: TLVEntryType.Author,
                                length: 64,
                                value: bech32ToHex(KieranPubKey),
                            })}`, children: "Kieran" })),
                    } }) })] }));
}
