var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Nip4ChatSystem_instances, _Nip4ChatSystem_cache, _Nip4ChatSystem_log, _Nip4ChatSystem_nip4Events;
import { ExternalStore, dedupe } from "@snort/shared";
import { EventKind, RequestBuilder, TLVEntryType, decodeTLV, encodeTLVEntries, } from "@snort/system";
import { ChatType, inChatWith, lastReadInChat } from "chat";
import { debug } from "debug";
export class Nip4ChatSystem extends ExternalStore {
    constructor(cache) {
        super();
        _Nip4ChatSystem_instances.add(this);
        _Nip4ChatSystem_cache.set(this, void 0);
        _Nip4ChatSystem_log.set(this, debug("NIP-04"));
        __classPrivateFieldSet(this, _Nip4ChatSystem_cache, cache, "f");
    }
    async onEvent(evs) {
        const dms = evs.filter(a => a.kind === EventKind.DirectMessage);
        if (dms.length > 0) {
            await __classPrivateFieldGet(this, _Nip4ChatSystem_cache, "f").bulkSet(dms);
            this.notifyChange();
        }
    }
    subscription(pk) {
        const rb = new RequestBuilder(`nip4:${pk.slice(0, 12)}`);
        const dms = __classPrivateFieldGet(this, _Nip4ChatSystem_cache, "f").snapshot();
        const dmSince = dms.reduce((acc, v) => (v.created_at > acc && v.kind === EventKind.DirectMessage ? (acc = v.created_at) : acc), 0);
        __classPrivateFieldGet(this, _Nip4ChatSystem_log, "f").call(this, "Loading DMS since %s", new Date(dmSince * 1000));
        rb.withFilter().authors([pk]).kinds([EventKind.DirectMessage]).since(dmSince);
        rb.withFilter().kinds([EventKind.DirectMessage]).tag("p", [pk]).since(dmSince);
        return rb;
    }
    takeSnapshot(p) {
        return this.listChats(p);
    }
    listChats(pk) {
        const myDms = __classPrivateFieldGet(this, _Nip4ChatSystem_instances, "m", _Nip4ChatSystem_nip4Events).call(this);
        const chatId = (a) => {
            return encodeTLVEntries("chat4", {
                type: TLVEntryType.Author,
                value: inChatWith(a, pk),
                length: 0,
            });
        };
        return dedupe(myDms.map(chatId)).map(a => {
            const messages = myDms.filter(b => chatId(b) === a);
            return Nip4ChatSystem.createChatObj(a, messages);
        });
    }
    static createChatObj(id, messages) {
        const last = lastReadInChat(id);
        const pk = decodeTLV(id).find(a => a.type === TLVEntryType.Author)?.value;
        return {
            type: ChatType.DirectMessage,
            id,
            unread: messages.reduce((acc, v) => (v.created_at > last ? acc++ : acc), 0),
            lastMessage: messages.reduce((acc, v) => (v.created_at > acc ? v.created_at : acc), 0),
            participants: [
                {
                    type: "pubkey",
                    id: pk,
                },
            ],
            messages: messages.map(m => ({
                id: m.id,
                created_at: m.created_at,
                from: m.pubkey,
                tags: m.tags,
                content: "",
                needsDecryption: true,
                decrypt: async (pub) => {
                    return await pub.decryptDm(m);
                },
            })),
            createMessage: async (msg, pub) => {
                return [await pub.sendDm(msg, pk)];
            },
            sendMessage: (ev, system) => {
                ev.forEach(a => system.BroadcastEvent(a));
            },
        };
    }
}
_Nip4ChatSystem_cache = new WeakMap(), _Nip4ChatSystem_log = new WeakMap(), _Nip4ChatSystem_instances = new WeakSet(), _Nip4ChatSystem_nip4Events = function _Nip4ChatSystem_nip4Events() {
    return __classPrivateFieldGet(this, _Nip4ChatSystem_cache, "f").snapshot().filter(a => a.kind === EventKind.DirectMessage);
};
