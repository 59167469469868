var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Nip24ChatSystem_instances, _Nip24ChatSystem_cache, _Nip24ChatSystem_nip24Events;
import { ExternalStore, dedupe } from "@snort/shared";
import { EventKind, encodeTLVEntries, TLVEntryType, decodeTLV, } from "@snort/system";
import { ChatType, lastReadInChat } from "chat";
export class Nip24ChatSystem extends ExternalStore {
    constructor(cache) {
        super();
        _Nip24ChatSystem_instances.add(this);
        _Nip24ChatSystem_cache.set(this, void 0);
        __classPrivateFieldSet(this, _Nip24ChatSystem_cache, cache, "f");
        __classPrivateFieldGet(this, _Nip24ChatSystem_cache, "f").hook(() => this.notifyChange(), "*");
    }
    subscription() {
        // ignored
        return undefined;
    }
    onEvent() {
        // ignored
    }
    listChats(pk) {
        const evs = __classPrivateFieldGet(this, _Nip24ChatSystem_instances, "m", _Nip24ChatSystem_nip24Events).call(this);
        const messages = evs.filter(a => a.to === pk);
        const chatId = (u) => {
            const pTags = dedupe([...(u.tags ?? []).filter(a => a[0] === "p").map(a => a[1]), u.inner.pubkey])
                .sort()
                .filter(a => a !== pk);
            return encodeTLVEntries("chat24", ...pTags.map(v => ({
                value: v,
                type: TLVEntryType.Author,
                length: v.length,
            })));
        };
        return dedupe(messages.map(a => chatId(a))).map(a => {
            const chatMessages = messages.filter(b => chatId(b) === a);
            return Nip24ChatSystem.createChatObj(a, chatMessages);
        });
    }
    static createChatObj(id, messages) {
        const last = lastReadInChat(id);
        const participants = decodeTLV(id)
            .filter(v => v.type === TLVEntryType.Author)
            .map(v => ({
            type: "pubkey",
            id: v.value,
        }));
        const title = messages.reduce((acc, v) => {
            const sbj = v.tags?.find(a => a[0] === "subject")?.[1];
            if (v.created_at > acc.t && sbj) {
                acc.title = sbj;
                acc.t = v.created_at;
            }
            return acc;
        }, {
            t: 0,
            title: "",
        });
        return {
            type: ChatType.PrivateDirectMessage,
            id,
            title: title.title,
            unread: messages.reduce((acc, v) => (v.created_at > last ? acc++ : acc), 0),
            lastMessage: messages.reduce((acc, v) => (v.created_at > acc ? v.created_at : acc), 0),
            participants,
            messages: messages.map(m => ({
                id: m.id,
                created_at: m.created_at,
                from: m.inner.pubkey,
                tags: m.tags,
                content: "",
                needsDecryption: true,
                decrypt: async (pub) => {
                    return await pub.decryptDm(m.inner);
                },
            })),
            createMessage: async (msg, pub) => {
                const gossip = pub.createUnsigned(EventKind.ChatRumor, msg, eb => {
                    for (const pt of participants) {
                        eb.tag(["p", pt.id]);
                    }
                    return eb;
                });
                const messages = [];
                for (const pt of participants) {
                    const recvSealedN = await pub.giftWrap(await pub.sealRumor(gossip, pt.id), pt.id);
                    messages.push(recvSealedN);
                }
                const sendSealed = await pub.giftWrap(await pub.sealRumor(gossip, pub.pubKey), pub.pubKey);
                return [...messages, sendSealed];
            },
            sendMessage: (ev, system) => {
                console.debug(ev);
                ev.forEach(a => system.BroadcastEvent(a));
            },
        };
    }
    takeSnapshot(p) {
        return this.listChats(p);
    }
}
_Nip24ChatSystem_cache = new WeakMap(), _Nip24ChatSystem_instances = new WeakSet(), _Nip24ChatSystem_nip24Events = function _Nip24ChatSystem_nip24Events() {
    const sn = __classPrivateFieldGet(this, _Nip24ChatSystem_cache, "f").takeSnapshot();
    return sn.filter(a => a.inner.kind === EventKind.SealedRumor);
};
