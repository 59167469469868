import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { TLVEntryType, decodeTLV } from "@snort/system";
import { useUserProfile } from "@snort/system-react";
import UnreadCount from "Element/UnreadCount";
import ProfileImage, { getDisplayName } from "Element/ProfileImage";
import { parseId } from "SnortUtils";
import NoteToSelf from "Element/NoteToSelf";
import useModeration from "Hooks/useModeration";
import useLogin from "Hooks/useLogin";
import usePageWidth from "Hooks/usePageWidth";
import NoteTime from "Element/NoteTime";
import DmWindow from "Element/DmWindow";
import Avatar from "Element/Avatar";
import Icon from "Icons/Icon";
import Text from "Element/Text";
import { System } from "index";
import { useChatSystem } from "chat";
import "./MessagesPage.css";
import messages from "./messages";
const TwoCol = 768;
const ThreeCol = 1500;
export default function MessagesPage() {
    const login = useLogin();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { id } = useParams();
    const [chat, setChat] = useState();
    const pageWidth = usePageWidth();
    useEffect(() => {
        const parsedId = parseId(id ?? "");
        setChat(id ? parsedId : undefined);
    }, [id]);
    const chats = useChatSystem();
    const unreadCount = useMemo(() => chats.reduce((p, c) => p + c.unread, 0), [chats]);
    function openChat(e, type, id) {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/messages/${encodeURIComponent(id)}`);
    }
    function noteToSelf(chat) {
        return (_jsx("div", { className: "flex mb10", onClick: e => openChat(e, chat.type, chat.id), children: _jsx(NoteToSelf, { clickable: true, className: "f-grow", link: "", pubkey: chat.id }) }, chat.id));
    }
    function conversationIdent(chat) {
        if (chat.participants.length === 1) {
            const p = chat.participants[0];
            if (p.type === "pubkey") {
                return _jsx(ProfileImage, { pubkey: p.id, className: "f-grow", link: "" });
            }
            else {
                return _jsx(ProfileImage, { pubkey: "", overrideUsername: p.id, className: "f-grow", link: "" });
            }
        }
        else {
            return (_jsxs("div", { className: "flex f-grow pfp-overlap", children: [chat.participants.map(v => (_jsx(ProfileImage, { pubkey: v.id, link: "", showUsername: false }))), _jsx("div", { className: "f-grow", children: chat.title })] }));
        }
    }
    function conversation(chat) {
        if (!login.publicKey)
            return null;
        const participants = chat.participants.map(a => a.id);
        if (participants.length === 1 && participants[0] === login.publicKey)
            return noteToSelf(chat);
        return (_jsxs("div", { className: "flex mb10", onClick: e => openChat(e, chat.type, chat.id), children: [conversationIdent(chat), _jsxs("div", { className: "nowrap", children: [_jsx("small", { children: _jsx(NoteTime, { from: chat.lastMessage * 1000, fallback: formatMessage({ id: "bxv59V", defaultMessage: "Just now" }) }) }), chat.unread > 0 && _jsx(UnreadCount, { unread: chat.unread })] })] }, chat.id));
    }
    return (_jsxs("div", { className: "dm-page", children: [(pageWidth >= TwoCol || !chat) && (_jsxs("div", { children: [_jsxs("div", { className: "flex", children: [_jsx("h3", { className: "f-grow", children: _jsx(FormattedMessage, { ...messages.Messages }) }), _jsx("button", { disabled: unreadCount <= 0, type: "button", children: _jsx(FormattedMessage, { ...messages.MarkAllRead }) })] }), chats
                        .sort((a, b) => {
                        const aSelf = a.participants.length === 1 && a.participants[0].id === login.publicKey;
                        const bSelf = b.participants.length === 1 && b.participants[0].id === login.publicKey;
                        if (aSelf || bSelf) {
                            return aSelf ? -1 : 1;
                        }
                        return b.lastMessage > a.lastMessage ? 1 : -1;
                    })
                        .map(conversation)] })), chat && _jsx(DmWindow, { id: chat }), pageWidth >= ThreeCol && chat && (_jsx("div", { children: _jsx(ProfileDmActions, { id: chat }) }))] }));
}
function ProfileDmActions({ id }) {
    const authors = decodeTLV(id)
        .filter(a => a.type === TLVEntryType.Author)
        .map(a => a.value);
    const pubkey = authors[0];
    const profile = useUserProfile(System, pubkey);
    const { block, unblock, isBlocked } = useModeration();
    function truncAbout(s) {
        if ((s?.length ?? 0) > 200) {
            return `${s?.slice(0, 200)}...`;
        }
        return s;
    }
    const blocked = isBlocked(pubkey);
    return (_jsxs(_Fragment, { children: [_jsx(Avatar, { user: profile, size: 210 }), _jsx("h2", { children: getDisplayName(profile, pubkey) }), _jsx("p", { children: _jsx(Text, { content: truncAbout(profile?.about) ?? "", tags: [], creator: pubkey, disableMedia: true, depth: 0 }) }), _jsxs("div", { className: "settings-row", onClick: () => (blocked ? unblock(pubkey) : block(pubkey)), children: [_jsx(Icon, { name: "block" }), blocked ? _jsx(FormattedMessage, { id: 'nDejmx', defaultMessage: 'Unblock' }) : _jsx(FormattedMessage, { id: 'Up5U7K', defaultMessage: 'Block' })] })] }));
}
