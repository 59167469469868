import { FeedCache } from "@snort/shared";
import { EventKind } from "@snort/system";
import { db } from "Db";
import { findTag, unwrap } from "SnortUtils";
export class GiftWrapCache extends FeedCache {
    constructor() {
        super("GiftWrapCache", db.gifts);
    }
    key(of) {
        return of.id;
    }
    async preload() {
        await super.preload();
        await this.buffer([...this.onTable]);
    }
    newest() {
        let ret = 0;
        this.cache.forEach(v => (ret = v.created_at > ret ? v.created_at : ret));
        return ret;
    }
    takeSnapshot() {
        return [...this.cache.values()];
    }
    async onEvent(evs, pub) {
        const unwrapped = (await Promise.all(evs.map(async (v) => {
            try {
                return {
                    id: v.id,
                    to: findTag(v, "p"),
                    created_at: v.created_at,
                    inner: await pub.unwrapGift(v),
                };
            }
            catch (e) {
                console.debug(e, v);
            }
        })))
            .filter(a => a !== undefined)
            .map(unwrap);
        // HACK: unseal to get p tags
        for (const u of unwrapped) {
            if (u.inner.kind === EventKind.SealedRumor) {
                const unsealed = await pub.unsealRumor(u.inner);
                u.tags = unsealed.tags;
            }
        }
        await this.bulkSet(unwrapped);
    }
}
