var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Nip29ChatSystem_instances, _Nip29ChatSystem_cache, _Nip29ChatSystem_nip29Chats;
import { ExternalStore, dedupe } from "@snort/shared";
import { RequestBuilder, EventKind } from "@snort/system";
import { unwrap } from "SnortUtils";
import { ChatType, lastReadInChat } from "chat";
export class Nip29ChatSystem extends ExternalStore {
    constructor(cache) {
        super();
        _Nip29ChatSystem_instances.add(this);
        _Nip29ChatSystem_cache.set(this, void 0);
        __classPrivateFieldSet(this, _Nip29ChatSystem_cache, cache, "f");
    }
    takeSnapshot() {
        return this.listChats();
    }
    subscription(id) {
        const gs = id.split("/", 2);
        const rb = new RequestBuilder(`nip29:${id}`);
        const last = this.listChats().find(a => a.id === id)?.lastMessage;
        rb.withFilter()
            .relay(`wss://${gs[0]}`)
            .kinds([EventKind.SimpleChatMessage])
            .tag("g", [`/${gs[1]}`])
            .since(last);
        rb.withFilter()
            .relay(`wss://${gs[0]}`)
            .kinds([EventKind.SimpleChatMetadata])
            .tag("d", [`/${gs[1]}`]);
        return rb;
    }
    async onEvent(evs) {
        const msg = evs.filter(a => a.kind === EventKind.SimpleChatMessage);
        if (msg.length > 0) {
            await __classPrivateFieldGet(this, _Nip29ChatSystem_cache, "f").bulkSet(msg);
            this.notifyChange();
        }
    }
    listChats() {
        const allMessages = __classPrivateFieldGet(this, _Nip29ChatSystem_instances, "m", _Nip29ChatSystem_nip29Chats).call(this);
        const groups = dedupe(allMessages
            .map(a => a.tags.find(b => b[0] === "g"))
            .filter(a => a !== undefined)
            .map(a => unwrap(a))
            .map(a => `${a[2]}${a[1]}`));
        return groups.map(g => {
            const [relay, channel] = g.split("/", 2);
            const messages = allMessages.filter(a => `${a.tags.find(b => b[0] === "g")?.[2]}${a.tags.find(b => b[0] === "g")?.[1]}` === g);
            const lastRead = lastReadInChat(g);
            return {
                type: ChatType.PublicGroupChat,
                id: g,
                title: `${relay}/${channel}`,
                unread: messages.reduce((acc, v) => (v.created_at > lastRead ? acc++ : acc), 0),
                lastMessage: messages.reduce((acc, v) => (v.created_at > acc ? v.created_at : acc), 0),
                messages: messages.map(m => ({
                    id: m.id,
                    created_at: m.created_at,
                    from: m.pubkey,
                    tags: m.tags,
                    needsDecryption: false,
                    content: m.content,
                    decrypt: async () => {
                        return m.content;
                    },
                })),
                participants: [
                    {
                        type: "generic",
                        id: "",
                        profile: {
                            name: `${relay}/${channel}`,
                        },
                    },
                ],
                createMessage: async (msg, pub) => {
                    return [
                        await pub.generic(eb => {
                            return eb
                                .kind(EventKind.SimpleChatMessage)
                                .tag(["g", `/${channel}`, relay])
                                .content(msg);
                        }),
                    ];
                },
                sendMessage: async (ev, system) => {
                    ev.forEach(async (a) => await system.WriteOnceToRelay(`wss://${relay}`, a));
                },
            };
        });
    }
}
_Nip29ChatSystem_cache = new WeakMap(), _Nip29ChatSystem_instances = new WeakSet(), _Nip29ChatSystem_nip29Chats = function _Nip29ChatSystem_nip29Chats() {
    return __classPrivateFieldGet(this, _Nip29ChatSystem_cache, "f").snapshot().filter(a => a.kind === EventKind.SimpleChatMessage);
};
