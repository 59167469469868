import { useSyncExternalStore } from "react";
import { Nip4ChatSystem } from "./nip4";
import { EventKind } from "@snort/system";
import { unwrap } from "@snort/shared";
import { Chats, GiftsCache } from "Cache";
import { findTag, unixNow } from "SnortUtils";
import { Nip29ChatSystem } from "./nip29";
import useModeration from "Hooks/useModeration";
import useLogin from "Hooks/useLogin";
import { Nip24ChatSystem } from "./nip24";
export var ChatType;
(function (ChatType) {
    ChatType[ChatType["DirectMessage"] = 1] = "DirectMessage";
    ChatType[ChatType["PublicGroupChat"] = 2] = "PublicGroupChat";
    ChatType[ChatType["PrivateGroupChat"] = 3] = "PrivateGroupChat";
    ChatType[ChatType["PrivateDirectMessage"] = 4] = "PrivateDirectMessage";
})(ChatType || (ChatType = {}));
export const Nip4Chats = new Nip4ChatSystem(Chats);
export const Nip29Chats = new Nip29ChatSystem(Chats);
export const Nip24Chats = new Nip24ChatSystem(GiftsCache);
/**
 * Extract the P tag of the event
 */
export function chatTo(e) {
    if (e.kind === EventKind.DirectMessage) {
        return unwrap(findTag(e, "p"));
    }
    else if (e.kind === EventKind.SimpleChatMessage) {
        const gt = unwrap(e.tags.find(a => a[0] === "g"));
        return `${gt[2]}${gt[1]}`;
    }
    throw new Error("Not a chat message");
}
export function inChatWith(e, myPk) {
    if (e.pubkey === myPk) {
        return chatTo(e);
    }
    else {
        return e.pubkey;
    }
}
export function selfChat(e, myPk) {
    return chatTo(e) === myPk && e.pubkey === myPk;
}
export function lastReadInChat(id) {
    const k = `dm:seen:${id}`;
    return parseInt(window.localStorage.getItem(k) ?? "0");
}
export function setLastReadIn(id) {
    const now = unixNow();
    const k = `dm:seen:${id}`;
    window.localStorage.setItem(k, now.toString());
}
export function useNip4Chat() {
    const { publicKey } = useLogin();
    return useSyncExternalStore(c => Nip4Chats.hook(c), () => Nip4Chats.snapshot(publicKey));
}
export function useNip29Chat() {
    return useSyncExternalStore(c => Nip29Chats.hook(c), () => Nip29Chats.snapshot());
}
export function useNip24Chat() {
    const { publicKey } = useLogin();
    return useSyncExternalStore(c => Nip24Chats.hook(c), () => Nip24Chats.snapshot(publicKey));
}
export function useChatSystem() {
    const nip4 = useNip4Chat();
    const nip24 = useNip24Chat();
    const { muted, blocked } = useModeration();
    return [...nip4, ...nip24].filter(a => !(muted.includes(a.id) || blocked.includes(a.id)));
}
