import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Layout.css";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useUserProfile } from "@snort/system-react";
import messages from "./messages";
import Icon from "Icons/Icon";
import { setShow, reset } from "State/NoteCreator";
import { System } from "index";
import useLoginFeed from "Feed/LoginFeed";
import { NoteCreator } from "Element/NoteCreator";
import { mapPlanName } from "./subscribe";
import useLogin from "Hooks/useLogin";
import Avatar from "Element/Avatar";
import { profileLink } from "SnortUtils";
import { getCurrentSubscription } from "Subscription";
import Toaster from "Toaster";
export default function Layout() {
    const location = useLocation();
    const replyTo = useSelector((s) => s.noteCreator.replyTo);
    const isNoteCreatorShowing = useSelector((s) => s.noteCreator.show);
    const isReplyNoteCreatorShowing = replyTo && isNoteCreatorShowing;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { publicKey, relays, preferences, subscriptions } = useLogin();
    const currentSubscription = getCurrentSubscription(subscriptions);
    const [pageClass, setPageClass] = useState("page");
    useLoginFeed();
    const handleNoteCreatorButtonClick = () => {
        if (replyTo) {
            dispatch(reset());
        }
        dispatch(setShow(true));
    };
    const shouldHideNoteCreator = useMemo(() => {
        const hideOn = ["/settings", "/messages", "/new", "/login", "/donate", "/p/", "/e", "/subscribe", "/live"];
        return isReplyNoteCreatorShowing || hideOn.some(a => location.pathname.startsWith(a));
    }, [location, isReplyNoteCreatorShowing]);
    const shouldHideHeader = useMemo(() => {
        const hideOn = ["/login", "/new"];
        return hideOn.some(a => location.pathname.startsWith(a));
    }, [location]);
    useEffect(() => {
        const widePage = ["/login", "/messages", "/live"];
        const noScroll = ["/messages", "/live"];
        if (widePage.some(a => location.pathname.startsWith(a))) {
            setPageClass(noScroll.some(a => location.pathname.startsWith(a)) ? "scroll-lock" : "");
        }
        else {
            setPageClass("page");
        }
    }, [location]);
    useEffect(() => {
        if (relays) {
            (async () => {
                for (const [k, v] of Object.entries(relays.item)) {
                    await System.ConnectToRelay(k, v);
                }
                for (const v of System.Sockets) {
                    if (!relays.item[v.address] && !v.ephemeral) {
                        System.DisconnectRelay(v.address);
                    }
                }
            })();
        }
    }, [relays]);
    function setTheme(theme) {
        const elm = document.documentElement;
        if (theme === "light" && !elm.classList.contains("light")) {
            elm.classList.add("light");
        }
        else if (theme === "dark" && elm.classList.contains("light")) {
            elm.classList.remove("light");
        }
    }
    useEffect(() => {
        const osTheme = window.matchMedia("(prefers-color-scheme: light)");
        setTheme("dark");
        osTheme.onchange = e => {
            if (preferences.theme === "system") {
                setTheme(e.matches ? "light" : "dark");
            }
        };
        return () => {
            osTheme.onchange = null;
        };
    }, [preferences.theme]);
    return (_jsxs("div", { className: pageClass, children: [!shouldHideHeader && (_jsxs("header", { className: "main-content mt5", children: [_jsxs("div", { className: "logo", onClick: () => navigate("/"), children: [_jsx(Icon, { name: "snort-by", size: 150, height: 20 }), currentSubscription && (_jsxs("small", { className: "flex", children: [_jsx(Icon, { name: "diamond", size: 10, className: "mr5" }), mapPlanName(currentSubscription.type)] }))] }), _jsx("div", { children: publicKey ? (_jsx(AccountHeader, {})) : (_jsx("button", { type: "button", onClick: () => navigate("/login"), children: _jsx(FormattedMessage, { ...messages.Login }) })) })] })), _jsx(Outlet, {}), !shouldHideNoteCreator && (_jsxs(_Fragment, { children: [_jsx("button", { className: "note-create-button", onClick: handleNoteCreatorButtonClick, children: _jsx(Icon, { name: "plus", size: 16 }) }), _jsx(NoteCreator, {})] })), _jsx(Toaster, {})] }));
}
const AccountHeader = () => {
    const navigate = useNavigate();
    const { publicKey, latestNotification, readNotifications } = useLogin();
    const profile = useUserProfile(System, publicKey);
    const hasNotifications = useMemo(() => latestNotification > readNotifications, [latestNotification, readNotifications]);
    const unreadDms = useMemo(() => (publicKey ? 0 : 0), [publicKey]);
    async function goToNotifications(e) {
        e.stopPropagation();
        // request permissions to send notifications
        if ("Notification" in window) {
            try {
                if (Notification.permission !== "granted") {
                    const res = await Notification.requestPermission();
                    console.debug(res);
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        navigate("/notifications");
    }
    return (_jsxs("div", { className: "header-actions", children: [_jsx("div", { className: "btn btn-rnd", onClick: () => navigate("/wallet"), children: _jsx(Icon, { name: "wallet" }) }), _jsx("div", { className: "btn btn-rnd", onClick: () => navigate("/search"), children: _jsx(Icon, { name: "search" }) }), _jsxs("div", { className: "btn btn-rnd", onClick: () => navigate("/messages"), children: [_jsx(Icon, { name: "envelope" }), unreadDms > 0 && _jsx("span", { className: "has-unread" })] }), _jsxs("div", { className: "btn btn-rnd", onClick: goToNotifications, children: [_jsx(Icon, { name: "bell" }), hasNotifications && _jsx("span", { className: "has-unread" })] }), _jsx(Avatar, { user: profile, onClick: () => {
                    if (profile) {
                        navigate(profileLink(profile.pubkey));
                    }
                } })] }));
};
