import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./ProfilePage.css";
import { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { encodeTLV, encodeTLVEntries, EventKind, NostrPrefix, TLVEntryType, tryParseNostrLink, } from "@snort/system";
import { LNURL } from "@snort/shared";
import { useUserProfile } from "@snort/system-react";
import { getReactions, unwrap } from "SnortUtils";
import { formatShort } from "Number";
import Note from "Element/Note";
import Bookmarks from "Element/Bookmarks";
import RelaysMetadata from "Element/RelaysMetadata";
import { TabElement } from "Element/Tabs";
import Icon from "Icons/Icon";
import useMutedFeed from "Feed/MuteList";
import useRelaysFeed from "Feed/RelaysFeed";
import usePinnedFeed from "Feed/PinnedFeed";
import useBookmarkFeed from "Feed/BookmarkFeed";
import useFollowersFeed from "Feed/FollowersFeed";
import useFollowsFeed from "Feed/FollowsFeed";
import useProfileBadges from "Feed/BadgesFeed";
import useModeration from "Hooks/useModeration";
import useZapsFeed from "Feed/ZapsFeed";
import { default as ZapElement } from "Element/Zap";
import FollowButton from "Element/FollowButton";
import { parseId, hexToBech32 } from "SnortUtils";
import Avatar from "Element/Avatar";
import Timeline from "Element/Timeline";
import Text from "Element/Text";
import SendSats from "Element/SendSats";
import Nip05 from "Element/Nip05";
import Copy from "Element/Copy";
import ProfileImage from "Element/ProfileImage";
import BlockList from "Element/BlockList";
import MutedList from "Element/MutedList";
import FollowsList from "Element/FollowListBase";
import IconButton from "Element/IconButton";
import FollowsYou from "Element/FollowsYou";
import QrCode from "Element/QrCode";
import Modal from "Element/Modal";
import BadgeList from "Element/BadgeList";
import { ProxyImg } from "Element/ProxyImg";
import useHorizontalScroll from "Hooks/useHorizontalScroll";
import { EmailRegex } from "Const";
import { getNip05PubKey } from "Pages/LoginPage";
import useLogin from "Hooks/useLogin";
import messages from "./messages";
import { System } from "index";
const NOTES = 0;
const REACTIONS = 1;
const FOLLOWERS = 2;
const FOLLOWS = 3;
const ZAPS = 4;
const MUTED = 5;
const BLOCKED = 6;
const RELAYS = 7;
const BOOKMARKS = 8;
function ZapsProfileTab({ id }) {
    const zaps = useZapsFeed(id);
    const zapsTotal = zaps.reduce((acc, z) => acc + z.amount, 0);
    return (_jsxs("div", { className: "main-content", children: [_jsx("div", { className: "zaps-total", children: _jsx(FormattedMessage, { ...messages.Sats, values: { n: formatShort(zapsTotal) } }) }), zaps.map(z => (_jsx(ZapElement, { showZapped: false, zap: z })))] }));
}
function FollowersTab({ id }) {
    const followers = useFollowersFeed(id);
    return _jsx(FollowsList, { pubkeys: followers, showAbout: true });
}
function FollowsTab({ id }) {
    const follows = useFollowsFeed(id);
    return _jsx(FollowsList, { pubkeys: follows, showAbout: true });
}
function RelaysTab({ id }) {
    const relays = useRelaysFeed(id);
    return _jsx(RelaysMetadata, { relays: relays });
}
function BookMarksTab({ id }) {
    const bookmarks = useBookmarkFeed(id);
    return (_jsx(Bookmarks, { pubkey: id, bookmarks: bookmarks.filter(e => e.kind === EventKind.TextNote), related: bookmarks.filter(e => e.kind !== EventKind.TextNote) }));
}
export default function ProfilePage() {
    const { formatMessage } = useIntl();
    const params = useParams();
    const navigate = useNavigate();
    const [id, setId] = useState();
    const user = useUserProfile(System, id);
    const loginPubKey = useLogin().publicKey;
    const isMe = loginPubKey === id;
    const [showLnQr, setShowLnQr] = useState(false);
    const [showProfileQr, setShowProfileQr] = useState(false);
    const aboutText = user?.about || "";
    const about = Text({
        content: aboutText,
        tags: [],
        creator: "",
        disableMedia: true,
    });
    const npub = !id?.startsWith(NostrPrefix.PublicKey) ? hexToBech32(NostrPrefix.PublicKey, id || undefined) : id;
    const lnurl = (() => {
        try {
            return new LNURL(user?.lud16 || user?.lud06 || "");
        }
        catch {
            // ignored
        }
    })();
    const website_url = user?.website && !user.website.startsWith("http") ? "https://" + user.website : user?.website || "";
    // feeds
    const { blocked } = useModeration();
    const pinned = usePinnedFeed(id);
    const muted = useMutedFeed(id);
    const badges = useProfileBadges(id);
    const follows = useFollowsFeed(id);
    // tabs
    const ProfileTab = {
        Notes: { text: formatMessage(messages.Notes), value: NOTES },
        Reactions: { text: formatMessage(messages.Reactions), value: REACTIONS },
        Followers: { text: formatMessage(messages.Followers), value: FOLLOWERS },
        Follows: { text: formatMessage(messages.Follows), value: FOLLOWS },
        Zaps: { text: formatMessage(messages.Zaps), value: ZAPS },
        Muted: { text: formatMessage(messages.Muted), value: MUTED },
        Blocked: { text: formatMessage(messages.BlockedCount, { n: blocked.length }), value: BLOCKED },
        Relays: { text: formatMessage(messages.Relays), value: RELAYS },
        Bookmarks: { text: formatMessage(messages.Bookmarks), value: BOOKMARKS },
    };
    const [tab, setTab] = useState(ProfileTab.Notes);
    const optionalTabs = [ProfileTab.Zaps, ProfileTab.Relays, ProfileTab.Bookmarks, ProfileTab.Muted].filter(a => unwrap(a));
    const horizontalScroll = useHorizontalScroll();
    useEffect(() => {
        if (params.id?.match(EmailRegex)) {
            getNip05PubKey(params.id).then(a => {
                setId(a);
            });
        }
        else {
            const nav = tryParseNostrLink(params.id ?? "");
            if (nav?.type === NostrPrefix.PublicKey || nav?.type === NostrPrefix.Profile) {
                // todo: use relays if any for nprofile
                setId(nav.id);
            }
            else {
                setId(parseId(params.id ?? ""));
            }
        }
        setTab(ProfileTab.Notes);
    }, [params]);
    function username() {
        return (_jsxs("div", { className: "name", children: [_jsxs("h2", { children: [user?.display_name || user?.name || "Nostrich", _jsx(FollowsYou, { followsMe: follows.includes(loginPubKey ?? "") })] }), user?.nip05 && _jsx(Nip05, { nip05: user.nip05, pubkey: user.pubkey }), _jsx(BadgeList, { badges: badges }), _jsx(Copy, { text: npub }), links()] }));
    }
    function links() {
        return (_jsxs("div", { className: "links", children: [user?.website && (_jsxs("div", { className: "website f-ellipsis", children: [_jsx(Icon, { name: "link" }), _jsx("a", { href: website_url, target: "_blank", rel: "noreferrer", children: user.website })] })), lnurl && (_jsxs("div", { className: "lnurl f-ellipsis", onClick: () => setShowLnQr(true), children: [_jsx(Icon, { name: "zap" }), lnurl.name] })), _jsx(SendSats, { lnurl: lnurl?.lnurl, show: showLnQr, onClose: () => setShowLnQr(false), author: id, target: user?.display_name || user?.name })] }));
    }
    function bio() {
        return (aboutText.length > 0 && (_jsx("div", { dir: "auto", className: "details", children: about })));
    }
    function tabContent() {
        if (!id)
            return null;
        switch (tab.value) {
            case NOTES:
                return (_jsxs(_Fragment, { children: [pinned
                            .filter(a => a.kind === EventKind.TextNote)
                            .map(n => {
                            return (_jsx(Note, { data: n, related: getReactions(pinned, n.id), options: { showTime: false, showPinned: true, canUnpin: id === loginPubKey } }, `pinned-${n.id}`));
                        }), _jsx(Timeline, { subject: {
                                type: "pubkey",
                                items: [id],
                                discriminator: id.slice(0, 12),
                            }, postsOnly: false, method: "LIMIT_UNTIL", loadMore: false, ignoreModeration: true, window: 60 * 60 * 6 }, id)] }));
            case ZAPS: {
                return _jsx(ZapsProfileTab, { id: id });
            }
            case FOLLOWS: {
                if (isMe) {
                    return _jsx(FollowsList, { pubkeys: follows, showFollowAll: !isMe, showAbout: false });
                }
                else {
                    return _jsx(FollowsTab, { id: id });
                }
            }
            case FOLLOWERS: {
                return _jsx(FollowersTab, { id: id });
            }
            case MUTED: {
                return _jsx(MutedList, { pubkeys: muted });
            }
            case BLOCKED: {
                return _jsx(BlockList, {});
            }
            case RELAYS: {
                return _jsx(RelaysTab, { id: id });
            }
            case BOOKMARKS: {
                return _jsx(BookMarksTab, { id: id });
            }
        }
    }
    function avatar() {
        return (_jsx("div", { className: "avatar-wrapper", children: _jsx(Avatar, { user: user }) }));
    }
    function renderIcons() {
        if (!id)
            return;
        const link = encodeTLV(NostrPrefix.Profile, id);
        return (_jsxs("div", { className: "icon-actions", children: [_jsx(IconButton, { onClick: () => setShowProfileQr(true), children: _jsx(Icon, { name: "qr", size: 16 }) }), showProfileQr && (_jsxs(Modal, { className: "qr-modal", onClose: () => setShowProfileQr(false), children: [_jsx(ProfileImage, { pubkey: id }), _jsx(QrCode, { data: link, className: "m10 align-center" }), _jsx(Copy, { text: link, className: "align-center" })] })), isMe ? (_jsx(_Fragment, { children: _jsx("button", { type: "button", onClick: () => navigate("/settings"), children: _jsx(FormattedMessage, { ...messages.Settings }) }) })) : (_jsxs(_Fragment, { children: [lnurl && (_jsx(IconButton, { onClick: () => setShowLnQr(true), children: _jsx(Icon, { name: "zap", size: 16 }) })), loginPubKey && (_jsx(_Fragment, { children: _jsx(IconButton, { onClick: () => navigate(`/messages/${encodeTLVEntries("chat4", {
                                    type: TLVEntryType.Author,
                                    length: 64,
                                    value: id,
                                })}`), children: _jsx(Icon, { name: "envelope", size: 16 }) }) }))] }))] }));
    }
    function userDetails() {
        if (!id)
            return;
        return (_jsxs("div", { className: "details-wrapper", children: [username(), _jsxs("div", { className: "profile-actions", children: [renderIcons(), !isMe && _jsx(FollowButton, { pubkey: id })] }), bio()] }));
    }
    function renderTab(v) {
        return _jsx(TabElement, { t: v, tab: tab, setTab: setTab }, v.value);
    }
    const w = window.document.querySelector(".page")?.clientWidth;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "profile flex", children: [user?.banner && _jsx(ProxyImg, { alt: "banner", className: "banner", src: user.banner, size: w }), _jsxs("div", { className: "profile-wrapper flex", children: [avatar(), userDetails()] })] }), _jsx("div", { className: "main-content", children: _jsxs("div", { className: "tabs", ref: horizontalScroll, children: [[ProfileTab.Notes, ProfileTab.Followers, ProfileTab.Follows].map(renderTab), optionalTabs.map(renderTab), isMe && blocked.length > 0 && renderTab(ProfileTab.Blocked)] }) }), _jsx("div", { className: "main-content", children: tabContent() })] }));
}
