import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./DmWindow.css";
import { useMemo } from "react";
import ProfileImage from "Element/ProfileImage";
import DM from "Element/DM";
import NoteToSelf from "Element/NoteToSelf";
import useLogin from "Hooks/useLogin";
import WriteMessage from "Element/WriteMessage";
import { useChatSystem } from "chat";
import { Nip4ChatSystem } from "chat/nip4";
import { FormattedMessage } from "react-intl";
export default function DmWindow({ id }) {
    const pubKey = useLogin().publicKey;
    const dms = useChatSystem();
    const chat = dms.find(a => a.id === id) ?? Nip4ChatSystem.createChatObj(id, []);
    function participant(p) {
        if (p.id === pubKey) {
            return _jsx(NoteToSelf, { className: "f-grow mb-10", pubkey: p.id });
        }
        if (p.type === "pubkey") {
            return _jsx(ProfileImage, { pubkey: p.id, className: "f-grow mb10" });
        }
        if (p?.profile) {
            return _jsx(ProfileImage, { pubkey: p.id, className: "f-grow mb10", profile: p.profile });
        }
        return _jsx(ProfileImage, { pubkey: p.id, className: "f-grow mb10", overrideUsername: p.id });
    }
    function sender() {
        if (chat.participants.length === 1) {
            return participant(chat.participants[0]);
        }
        else {
            return (_jsxs("div", { className: "flex pfp-overlap mb10", children: [chat.participants.map(v => (_jsx(ProfileImage, { pubkey: v.id, showUsername: false }))), chat.title ?? _jsx(FormattedMessage, { id: 'eXT2QQ', defaultMessage: 'Group Chat' })] }));
        }
    }
    return (_jsxs("div", { className: "dm-window", children: [_jsx("div", { children: sender() }), _jsx("div", { children: _jsx("div", { className: "flex f-col", children: chat && _jsx(DmChatSelected, { chat: chat }) }) }), _jsx("div", { children: _jsx(WriteMessage, { chat: chat }) })] }));
}
function DmChatSelected({ chat }) {
    const { publicKey: myPubKey } = useLogin();
    const sortedDms = useMemo(() => {
        const myDms = chat?.messages;
        if (myPubKey && myDms) {
            // filter dms in this chat, or dms to self
            return [...myDms].sort((a, b) => a.created_at - b.created_at);
        }
        return [];
    }, [chat, myPubKey]);
    return (_jsx(_Fragment, { children: sortedDms.map(a => (_jsx(DM, { data: a, chat: chat }, a.id))) }));
}
